var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.detailInfo)?_c('div',{staticClass:"page"},[_c('van-nav-bar',{attrs:{"title":"补充信息","left-arrow":""},on:{"click-left":_vm.onClickLeft},scopedSlots:_vm._u([{key:"right",fn:function(){return [_c('UserIcon')]},proxy:true}],null,false,2201647186)}),_c('div',{staticClass:"content"},[_c('CardCustomer',{staticClass:"customer",attrs:{"data":_vm.detailInfo.customerDTO}}),_c('div',{staticClass:"form"},_vm._l((_vm.form),function(item,index){return _c('div',{key:index,staticClass:"item"},[(_vm.form.length > 1 && !_vm.readonly)?_c('div',{staticClass:"close",on:{"click":function($event){$event.stopPropagation();return _vm.removeItem(index)}}},[_vm._v(" X ")]):_vm._e(),_c('div',[_c('van-field',{attrs:{"readonly":"","clickable":"","name":"picker","value":_vm._f("enumMessage")(item.type,_vm.getHomeAdditionTypeEnum),"label":"补充类型","placeholder":"补充类型"},on:{"click":function($event){!_vm.readonly && _vm.showSelect(item, _vm.getHomeAdditionTypeEnum, 'type')}}}),(
              [
                'LIVE_ADDRESS', // 居住地址
                'COMPANY_ADDRESS', // 工作地址
                'REGISTER_ADDRESS' ].includes(item.type)
            )?[_c('van-field',{attrs:{"readonly":"","clickable":"","name":"area","value":_vm.getRegionValue(item),"label":"选择省市区","placeholder":"点击选择省市区"},on:{"click":function($event){!_vm.readonly && _vm.showAreaSelect(item)}}}),_c('van-field',{attrs:{"readonly":_vm.readonly,"label":"地址","placeholder":"地址"},model:{value:(item.address),callback:function ($$v) {_vm.$set(item, "address", $$v)},expression:"item.address"}})]:_vm._e(),(
              [
                'CONTACT_WAY' ].includes(item.type)
            )?[_c('van-field',{attrs:{"readonly":_vm.readonly,"label":"姓名","placeholder":"姓名"},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}}),_c('van-field',{attrs:{"readonly":"","clickable":"","name":"picker","value":_vm._f("enumMessage")(item.relation,_vm.getRelationEnum),"label":"与本人关系","placeholder":"与本人关系"},on:{"click":function($event){!_vm.readonly && _vm.showSelect(item, _vm.getRelationEnum, 'relation')}}}),_c('van-field',{attrs:{"readonly":_vm.readonly,"type":"tel","label":"电话","placeholder":"电话"},model:{value:(item.phone),callback:function ($$v) {_vm.$set(item, "phone", $$v)},expression:"item.phone"}})]:_vm._e(),(
              [
                'GPS_CODE' ].includes(item.type)
            )?[_c('van-field',{attrs:{"readonly":_vm.readonly,"label":"编码","placeholder":"编码"},model:{value:(item.coding),callback:function ($$v) {_vm.$set(item, "coding", $$v)},expression:"item.coding"}})]:_vm._e()],2)])}),0),_c('div',{staticClass:"add"},[(!_vm.readonly)?_c('van-button',{attrs:{"type":"info","size":"small"},on:{"click":_vm.addItem}},[_vm._v("新增")]):_vm._e()],1)],1),_c('div',{staticClass:"actions"},[(!_vm.readonly)?_c('van-button',{attrs:{"type":"info","block":""},on:{"click":_vm.handleSubmit}},[_vm._v("提交")]):_vm._e()],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showPicker),callback:function ($$v) {_vm.showPicker=$$v},expression:"showPicker"}},[_c('van-picker',{attrs:{"show-toolbar":"","columns":_vm.selectInfo.columns,"default-index":_vm.selectInfo.defaultIndex,"value-key":"displayName"},on:{"confirm":_vm.onConfirm,"cancel":function($event){_vm.showPicker = false}}})],1),_c('van-popup',{attrs:{"position":"bottom"},model:{value:(_vm.showArea),callback:function ($$v) {_vm.showArea=$$v},expression:"showArea"}},[_c('van-area',{attrs:{"value":_vm.areaValue,"area-list":_vm.areaList},on:{"confirm":_vm.onAreaConfirm,"cancel":function($event){_vm.showArea = false}}})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }