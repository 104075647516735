<template>
  <div class="page" v-if="detailInfo">
    <van-nav-bar title="补充信息" left-arrow @click-left="onClickLeft" >
      <template #right>
        <UserIcon />
      </template>
    </van-nav-bar>
    <div class="content">
      <CardCustomer
        class="customer"
        :data="detailInfo.customerDTO"
      ></CardCustomer>

      <div class="form">
        <div v-for="(item, index) in form" :key="index" class="item">
          <div
            v-if="form.length > 1 && !readonly"
            class="close"
            @click.stop="removeItem(index)"
          >
            X
          </div>
          <div>
            <van-field
              readonly
              clickable
              name="picker"
              :value="item.type | enumMessage(getHomeAdditionTypeEnum)"
              label="补充类型"
              placeholder="补充类型"
              @click="
                !readonly && showSelect(item, getHomeAdditionTypeEnum, 'type')
              "
            />
            <template
              v-if="
                [
                  'LIVE_ADDRESS', // 居住地址
                  'COMPANY_ADDRESS', // 工作地址
                  'REGISTER_ADDRESS', // 户籍地址
                ].includes(item.type)
              "
            >
              <van-field
                readonly
                clickable
                name="area"
                :value="getRegionValue(item)"
                label="选择省市区"
                placeholder="点击选择省市区"
                @click="!readonly && showAreaSelect(item)"
              />
              <van-field
                v-model="item.address"
                :readonly="readonly"
                label="地址"
                placeholder="地址"
              />
            </template>
            <template
              v-if="
                [
                  'CONTACT_WAY', // 联系方式
                ].includes(item.type)
              "
            >
              <van-field
                v-model="item.name"
                :readonly="readonly"
                label="姓名"
                placeholder="姓名"
              />
              <van-field
                readonly
                clickable
                name="picker"
                :value="item.relation | enumMessage(getRelationEnum)"
                label="与本人关系"
                placeholder="与本人关系"
                @click="
                  !readonly && showSelect(item, getRelationEnum, 'relation')
                "
              />
              <van-field
                v-model="item.phone"
                :readonly="readonly"
                type="tel"
                label="电话"
                placeholder="电话"
              />
            </template>
            <template
              v-if="
                [
                  'GPS_CODE', // GPS编码
                ].includes(item.type)
              "
            >
              <van-field
                v-model="item.coding"
                :readonly="readonly"
                label="编码"
                placeholder="编码"
              />
            </template>
          </div>
        </div>
      </div>

      <div class="add">
        <van-button v-if="!readonly" type="info" size="small" @click="addItem"
          >新增</van-button
        >
      </div>
    </div>

    <div class="actions">
      <van-button v-if="!readonly" type="info" block @click="handleSubmit"
        >提交</van-button
      >
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        show-toolbar
        :columns="selectInfo.columns"
        :default-index="selectInfo.defaultIndex"
        value-key="displayName"
        @confirm="onConfirm"
        @cancel="showPicker = false"
      />
    </van-popup>
    <van-popup v-model="showArea" position="bottom">
      <van-area
        :value="areaValue"
        :area-list="areaList"
        @confirm="onAreaConfirm"
        @cancel="showArea = false"
      />
    </van-popup>
  </div>
</template>
<script>
import * as _ from "lodash";
import Api from "@/api/caseHandle";
import { loadRegions } from "@/libs/utils";
import { Toast } from "vant";
const DEFAULT_ITEM = {
  address: "",
  assignNo: "",
  city: "",
  cityCode: "",
  coding: "",
  name: "",
  phone: "",
  province: "",
  provinceCode: "",
  region: "",
  regionCode: "",
  relation: "",
  type: "",
};

export default {
  data() {
    return {
      homeNo: this.$route.params.homeNo,
      readonly: !!this.$route.query.readonly,
      detailInfo: null,
      showPicker: false,
      showArea: false,
      areaList: null,

      selectInfo: {
        parent: null,
        defaultIndex: 0,
        columns: [],
        key: "",
      },

      selectAreaItem: null,
      areaValue: null,

      form: [
        {
          ..._.cloneDeep(DEFAULT_ITEM),
        },
      ],
    };
  },
  methods: {
    getRegionValue(item) {
      const { province, city, region } = item;
      var regionList = [province, city, region];
      var exist = regionList.find((i) => i != null && i != "");
      if (!exist) {
        return "";
      }
      return regionList.join(" ");
    },
    showSelect(parent, enums, key) {
      const value = parent[key];
      const defaultIndex = Math.max(
        enums.map((e) => e.value).indexOf(value),
        0
      );
      this.selectInfo = {
        parent,
        defaultIndex,
        columns: enums,
        key,
      };
      this.showPicker = true;
    },

    onConfirm(value) {
      const { selectInfo } = this;
      selectInfo.parent[selectInfo.key] = value.value;
      this.showPicker = false;
      switch (selectInfo.key) {
        case "type": // 补充类型
          this.validateItem(selectInfo.parent);
          break;
      }
    },

    // 校验数据 & 格式化数据 （移除）
    validateItem(item) {
      const { type } = item;
      switch (type) {
        case "LIVE_ADDRESS":
        case "COMPANY_ADDRESS":
        case "REGISTER_ADDRESS":
          var {
            province,
            provinceCode,
            city,
            cityCode,
            region,
            regionCode,
            address,
          } = item;
          Object.assign(
            item,
            {
              ...DEFAULT_ITEM,
            },
            {
              type,
              province,
              provinceCode,
              city,
              cityCode,
              region,
              regionCode,
              address,
            }
          );
          if (!provinceCode || !cityCode || !regionCode) {
            return "请选择省市区";
          }
          if (!address) {
            return "请输入地址";
          }
          break;
        case "CONTACT_WAY":
          var { name, relation, phone } = item;
          Object.assign(
            item,
            {
              ...DEFAULT_ITEM,
            },
            {
              type,
              name,
              relation,
              phone,
            }
          );
          if (!name) {
            return "请输入姓名";
          }
          if (!phone) {
            return "请选择关系";
          }
          if (!phone) {
            return "请输入电话";
          }
          if (!/^1[\d]{10}$/.test(phone)) {
            return `请输入正确的手机号`;
          }
          break;
        case "GPS_CODE":
          var { coding } = item;
          Object.assign(
            item,
            {
              ...DEFAULT_ITEM,
            },
            {
              type,
              coding,
            }
          );
          if (!coding) {
            return "请输入编码";
          }
          break;
        default:
          return "请选择补充类型";
      }
      return null;
    },

    // 显示地区选择
    showAreaSelect(item) {
      this.selectAreaItem = item;
      this.areaValue = item.regionCode;
      this.showArea = true;
    },

    // 确认地区选择
    onAreaConfirm(areas) {
      const { selectAreaItem } = this;
      const [province, city, region] = areas;
      Object.assign(selectAreaItem, {
        province: province.name,
        provinceCode: province.code,
        city: city.name,
        cityCode: city.code,
        region: region.name,
        regionCode: region.code,
      });
      this.showArea = false;
      this.areaValue = null;
    },

    addItem() {
      this.form.push({
        ..._.cloneDeep(DEFAULT_ITEM),
      });
    },

    removeItem(index) {
      this.form.splice(index, 1);
    },

    async handleSubmit() {
      try {
        const loading = Toast.loading();
        const params = _.cloneDeep(this.form);

        for (const item of params) {
          var msg = this.validateItem(item);
          if (msg) {
            if (item.type) {
              var typeStr = this.$options.filters.enumMessage(
                item.type,
                this.getHomeAdditionTypeEnum
              );
              msg = `【${typeStr}】：${msg}`;
            }
            Toast(msg);
            return;
          }
        }

        const { detailInfo } = this;
        for (const item of params) {
          item.assignNo = detailInfo.assignNo;
        }
        var res = await Api.saveHomeReportAddition(params);

        loading.close();
        Toast.success(res.message);
        this.$router.go(-1);
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 加载详情
    async loadDetail() {
      const loading = Toast.loading();
      try {
        const res = await Api.findDetailByHomeNo(this.homeNo);
        this.detailInfo = res.data;
        await this.reportAdditionList(this.detailInfo.assignNo);
        loading.close();
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 查询补充信息
    async reportAdditionList(assignNo) {
      try {
        const res = await Api.reportAdditionList(assignNo);
        const { data } = res;
        if (data && data.length) {
          this.form = data;
        }
      } catch (error) {
        Toast.fail(error);
      }
    },

    // 加载省市区
    async loadRegions() {
      this.areaList = await loadRegions();
    },
  },
  mounted() {
    this.loadDetail();
    this.loadRegions();
    this.fetchEnums("getHomeAdditionTypeEnum", "getRelationEnum");
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/css/variables.less";
.page {
  height: 100%;
  background: @pageBg;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    overflow-y: auto;
    .customer {
      margin: 5px 5px;
    }

    .form {
      .item {
        margin-top: 20px;
        margin-bottom: 20px;
        position: relative;

        .close {
          position: absolute;
          width: 25px;
          height: 25px;
          background: rgba(77, 75, 75, 0.5);
          font-size: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          border-radius: 50%;
          top: -10px;
          right: 5px;
          z-index: 99;
        }
      }
    }
    .add {
      text-align: center;
      padding: 20px 0px 10px 0px;
      .van-button {
        min-width: 8em;
      }
    }
  }

  .actions {
    .van-button {
      border-radius: 0px;
    }
  }
}
</style>